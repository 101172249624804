import React, { useCallback } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'
import { UserSegment } from 'common/enum'
import _ from 'lodash'

const SegmentTargetingTable = props => {
  const { data, page, onPageChange, enumMap } = props

  const getLabel = useCallback((key, value) => _.startCase((enumMap[key] || {})[value] || 'All'), [enumMap])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Workout ID</TableCell>
                  <TableCell>Gender Segment</TableCell>
                  <TableCell>Age Segment</TableCell>
                  <TableCell>BMI Segment</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.workout_id}</TableCell>
                    <TableCell>{getLabel(UserSegment.Gender, row.gender_segment)}</TableCell>
                    <TableCell>{getLabel(UserSegment.Age, row.age_segment)}</TableCell>
                    <TableCell>{getLabel(UserSegment.BMI, row.bmi_segment)}</TableCell>
                    <TableCell>{row.start_time}</TableCell>
                    <TableCell>{row.end_time}</TableCell>
                    <TableCell>{ booleanElement(row.active) }</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/workout/segment-targeting/${row.id}/edit`} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onPageChange={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default SegmentTargetingTable