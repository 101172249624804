import _ from 'lodash'
import { useEffectOnce } from 'react-use'
import SegmentTable from './table'
import { useLocation } from 'react-router-dom'
import SegmentToolBar from './toolbar'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { getSegmentTargetingListPromise } from 'api/segmentTargeting'
import { getEnumOptionsPromise } from 'api/Util'

export default function (props) {
  const ref = useRef()
  const location = useLocation()
  const [segment, setSegment] = useState({})
  const [enumMap, setEnumMap] = useState({})
  const [options, setOptions] = useState({})
  const [params, setParams] = useState({ page: 1, filters: {} })

  useEffect(() => {
    const _enums = {}
    const _options = {}
    getEnumOptionsPromise('UserGenderSegment', 'UserAgeSegment', 'UserBMISegment').then(resp => {
      Object.entries(resp).forEach(([k, v]) => {
        _enums[k] = _.invert(v)
        _options[k] = Object.entries(v).map(([label, value]) => ({ label, value }))
      })

      setEnumMap(_enums)
      setOptions(_options)
    })
  }, [])

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  const loadData = useCallback(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    getSegmentTargetingListPromise(too).then(resp => setSegment(resp))
  }, [location.search])

  // load data
  useEffect(() => {
    loadData()
  }, [loadData])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  function handlePageChange (event, page) {
    handleParamsChange({ page: page + 1 })
  }
  // end

  return (
    <div className={'root'}>
      <SegmentToolBar {...props} params={params} onParamsChange={handleParamsChange} options={options} />
      <div className={'content'}>
        <SegmentTable {...props} data={segment} page={params.page} onPageChange={handlePageChange} enumMap={enumMap} />
      </div>
    </div>
  )
}
