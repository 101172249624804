import _ from 'lodash'
import 'assets/scss/form2.scss'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import FormTextField from 'components/FormTextField'
import DateTimeUTCInput from 'components/DateTimeUTCInput'
import { getEnumOptionsPromise } from 'api/Util'
import { UserSegment } from 'common/enum'
import { upsertSegmentTargetingPromise } from 'api/segmentTargeting'

export default function (props) {
  const { data, history } = props
  const [segmentTargeting, setSegmentTargeting] = useState({})
  const columnsNotNull = ['workout_id']
  const [options, setOptions] = useState({})

  useLayoutEffect(() => {
    const _options = {}
    getEnumOptionsPromise('UserGenderSegment', 'UserAgeSegment', 'UserBMISegment').then(resp => {
      Object.entries(resp).forEach(([k, v]) => {
        const entires = Object.entries(v).map(([label, value]) => ({ label: _.startCase(label), value }))
        _options[k] = entires.concat({ label: 'All', value: null })
      })

      setOptions(_options)
    })
  }, [])

  useLayoutEffect(() => {
    if (data) setSegmentTargeting(data)
  }, [data])

  const handleChange = useCallback((key, value, other) => {
    setSegmentTargeting({ ...segmentTargeting, [key]: value, ...other })
  }, [segmentTargeting])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertSegmentTargetingPromise(segmentTargeting)
      .then(() => history.goBack())
  }, [segmentTargeting, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!segmentTargeting[cname]) return true
  }, [segmentTargeting, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Segment Targeting
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
              <Switch id='active' name={'active'} checked={Boolean(segmentTargeting.active)} onChange={e => handleChange('active', e.target.checked)} />
            </Grid>
            
            <Grid container item xs={12} className='form-card-content'>
              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Workout ID</label>
                  <FormTextField name='workout_id' value={segmentTargeting.workout_id} onChange={handleChange} />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Gender Segment</label>
                  <AutoCompleteNew name='gender_segment' options={options[UserSegment.Gender] || []} value={segmentTargeting.gender_segment} className='full-width' variant='outlined' inputLeft={true}
                    onChange={(event, selected) => handleChange('gender_segment', selected && selected.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Age Segment</label>
                  <AutoCompleteNew name='age_segment' options={options[UserSegment.Age] || []} value={segmentTargeting.age_segment} className='full-width' variant='outlined' inputLeft={true}
                    onChange={(event, selected) => handleChange('age_segment', selected && selected.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>BMI Segment</label>
                  <AutoCompleteNew name='bmi_segment' options={options[UserSegment.BMI] || []} value={segmentTargeting.bmi_segment} className='full-width' variant='outlined' inputLeft={true}
                    onChange={(event, selected) => handleChange('bmi_segment', selected && selected.value)}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <label className='full-width'>Start Time</label>
                <DateTimeUTCInput value={segmentTargeting.start_time} handleChange={start_time => handleChange('start_time', start_time)} />
              </Grid>

              <Grid item xs={6}>
                <label className='full-width'>End Time</label>
                <DateTimeUTCInput value={segmentTargeting.end_time} handleChange={end_time => handleChange('end_time', end_time)} />
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
